import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import SendIcon from '../Images/paper-plane-solid.svg';

import {
    FormContainer,
    Form,
    Input,
    Button
} from '../Styles/SubscribeForm.style';

class SubscribeForm extends React.Component {

    state = {
        email: '',
        message: ''
    };

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState({ message: result.msg });
    };

    render() {
        return (
            <FormContainer>
                <div>Subscribe and get your free Front-end Interview Prep Guide</div>
                <Form name="subscribeForm" method="POST" onSubmit={this.handleSubmit}>
                    <Input type="email" name="email" placeholder="Enter Your Email Address..." 
                                value={this.state.email} onChange={this.handleInputChange} />
                    <Button type="submit" icon={SendIcon}/>
                </Form>
                <div dangerouslySetInnerHTML={{ __html: this.state.message}} />
            </FormContainer>
        );
    }
}


export default SubscribeForm;