import styled from "styled-components";

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
`;

export const Form = styled.form`
    width: 100%;
    padding: 20px 0;
    border-radius:20px;
`;

export const Input = styled.input`
    background-color: rgb(248, 248, 248);  
    border-radius: 13px;
    color: black;
    font-size: 15px;
    line-height: 1.8;
    outline: none;
    width: 220px;
    height: 40px;
    border: none;
    padding: 10px 15px;
`;

export const Button = styled.button`
    width: 30px;
    height: 30px;
    background: url(${props => props.icon}) no-repeat;
    outline: none; 
    border: none;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
`;